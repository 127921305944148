import React from 'react';
import { IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link as Scroll } from 'react-scroll';
import texts from '../assets/content/texts'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import './Slogan.css';

export default function How() {
    return (
        <div class='textRootGreen' id="how">
            <Card class='card'>
                <div class='header'>
                    <div class='colorTextWhite'>How?</div>
                </div>
                <CardContent>
                    <Typography
                        variant="body2"
                        component="p">
                        <p class='whiteText'>{texts[3].text}</p>
                        <p class='whiteText'>{texts[4].text}</p>
                    </Typography>
                </CardContent>
                <div class='explanation'>
                    <Scroll to="tryIt" smooth={true} className='downButton' >
                        <IconButton>
                            <KeyboardArrowDownIcon className='goDownWhite'/>
                        </IconButton>
                    </Scroll>
                </div>
            </Card> 
        </div>
    );
}