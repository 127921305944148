const members = [
    {
        name: 'Andre Allese',
        title: 'Founder',
        desc: 'Developer at startup Finisher.TV and Multishot.Has developed many automated video and production systems.',
        imageUrl: process.env.PUBLIC_URL + '/pictures/Andre.jpg',
    },
    {
        name: 'Hannes Mäeorg',
        title: 'Co-founder',
        desc: 'Software engineer, who has experience with a wide variety of software solutions.',
        imageUrl: process.env.PUBLIC_URL + '/pictures/Hannes.jpg',
    },
    {
        name: 'Kaupo Kuusemäe',
        title: 'Co-founder',
        desc: 'Professional video producer. He has worked with different database solutions as a data specialist',
        imageUrl: process.env.PUBLIC_URL + '/pictures/Kaupo.jpg',
    },
    {
        name: 'Reigo Kimmel',
        title: 'Marketing and sales',
        desc: 'Marketing specialists. Expert in content creation domain.',
        imageUrl: process.env.PUBLIC_URL + '/pictures/Reigo.jpg',
    },
];

export default members;