import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import './VideoWrapper.css';

export default function VideoWrapper({ video }) {

  return (
    <Card class='videowrapperroot'>
      <CardMedia
          class='videowrappermedia'
          component='iframe'
          title='test'
          height="169"
          src={video.url}
      />
      <CardContent class="cardContent">
          <Typography gutterBottom variant="h5" component="h2" class='title'>
            {video.title}
          </Typography>
      </CardContent>
    </Card>
  );
}