import React from 'react'
import { Link } from 'react-router-dom';
import './Footer.css';
import template from '../assets/pictures/TIPIT_LOGO_V2_LOGO_WHITE-05.png'
import { Link as Scroll } from 'react-scroll';

export default function Footer() {
    return (
        <div className='footer-container'>
            <div class='footer-links'>
                <div className='footer-link-wrapper'>
                    <div class='footer-link-items'>
                        <div class='footer-title' >About Us</div>
                        <Link to='/login' class='footer-text'>How it works</Link>
                        <Link to='/sign-up' class='footer-text'>Terms of Service</Link>
                    </div>
                    <div class='footer-link-items'>
                    <div class='footer-title' >Contact Us</div>
                        <Link to='/' class='footer-text'>Contact</Link>
                        <Link to='/' class='footer-text'>Support</Link>
                    </div>
                </div>
                <div className='footer-link-wrapper'>
                    <div class='footer-link-items'>
                    <div class='footer-title' >Social Media</div>
                        <Link to='/' class='footer-text'>Instagram</Link>
                        <Link to='/' class='footer-text'>Facebook</Link>
                        <Link to='/' class='footer-text'>Youtube</Link>
                    </div>
                </div>
            </div>
            <section class='social-media'>
                <div class='social-media-wrap'>
                    <div class='footer-logo'>
                    <Scroll to="main" smooth={true} className='downButton' >
                        <img className='logo' src={template} alt="al"/>
                    </Scroll>
                    </div>
                    <small class='website-rights'>TIPIT © 2021</small>
                    <div class='social-icons'>
                        <Link
                        class='social-icon-link facebook'
                        to='/'
                        target='_blank'
                        aria-label='Facebook'
                        >
                        <i class='fab fa-facebook-f' />
                        </Link>
                        <Link
                        class='social-icon-link instagram'
                        to='/'
                        target='_blank'
                        aria-label='Instagram'
                        >
                        <i class='fab fa-instagram' />
                        </Link>
                        <Link
                        class='social-icon-link youtube'
                        to='/'
                        target='_blank'
                        aria-label='Youtube'
                        >
                        <i class='fab fa-youtube' />
                        </Link>
                        <Link
                        class='social-icon-link twitter'
                        to='/'
                        target='_blank'
                        aria-label='Twitter'
                        >
                        <i class='fab fa-twitter' />
                        </Link>
                        <Link
                        class='social-icon-link twitter'
                        to='/'
                        target='_blank'
                        aria-label='LinkedIn'
                        >
                        <i class='fab fa-linkedin' />
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    )
}