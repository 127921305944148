const texts = [
    {
        title: 'Description',
        text: "TIPIT makes it possible to support your favorite content creator right on the creator's page. It is really easy -  just push the TIP button on every post you like, choose the amount you'd like to TIP and it is done! You like it, tip it!",
    },
    {
        title: 'Why',
        text: "Content creators are supported (financially as donations) by their fans but the options to do that will usually direct fans away from the content to some other page, where they must create an account to make a one-time or monthly payment. This is not very convenient and requires extra effort and time to make this support happen. Also, these solutions will only make it possible to support the content creator rather than the content itself.",
    },
    {
        title: 'Content Creator',
        text: "The content creator is anyone who promotes their work, products, talent etc. It is not social media stars only - it can be anyone who has a homepage and wants to make income from that content.",
    },
    {
        title: 'How',
        text: "The content creator (CC) has to create a TIPIT user and connect it to their bank account. TIPIT makes it possible to add a TIP button to every post of the CC. It is a widget that can be easily installed on many different webpage creation platforms or they can also use code that we provide for this. That means that to each post (photo, video, blog post, song etc.) the TIP button can be added right next to the Like and Share buttons. Anyone visiting the site will now see the TIP support button under these posts and clicking on it will make it possible to directly make the transaction without leaving the site - just pick the amount and confirm it with a click. Done! To support the CC with one push the user also needs a TIPIT account. If the user does not have a TIPIT account then pushing the button still will give the straight option to tip the amount the user likes and a standard web payment will follow."
    },
    {
        title: 'Feedback',
        text: "If every post can be tipped separately then this will also provide good feedback to the content creator on what matters the most."
    },
];

export default texts;