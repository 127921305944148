import React from 'react';
import TeamMember from './TeamMember';
import members from '../assets/content/members'
import { IconButton } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Link as Scroll } from 'react-scroll';
import './Team.css';

export default function Presentations() {
    return (
        <div className='teamroot' id="team">
            <div className='header'>
                <div class='colorTextWhite'>Team</div>
            </div>
            <div className='members'>
                <TeamMember member={members[0]}/>
                <TeamMember member={members[1]}/>
                <TeamMember member={members[2]}/>
                <TeamMember member={members[3]}/>
            </div>
            <div>
                <Scroll to="main" smooth={true} className='downButton' >
                    <IconButton>
                        <KeyboardArrowUpIcon className='goDownWhite'/>
                    </IconButton>
                </Scroll>
            </div>
        </div>
    )
}