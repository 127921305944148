import React from 'react';
import VideoWrapper from './VideoWrapper';
import videos from '../assets/content/videos'
import { IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link as Scroll } from 'react-scroll';
import texts from '../assets/content/texts'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import './Presentations.css';

export default function Presentations() {
    return (
        <div class="presentationsroot" id="presentations">
            <div class='textStyle'>
                <Card class='card'>
                    <CardContent>
                        <Typography
                            variant="body2"
                            component="p">
                            <p class='whiteText'>{texts[2].text}</p>
                        </Typography>
                    </CardContent>
                </Card> 
            </div>     
            <div className='presentationsroot'>
                <div class='videos'>
                    <VideoWrapper video={videos[0]}/>
                    <VideoWrapper video={videos[1]}/>
                </div>
                <div>
                    <Scroll to="how" smooth={true} className='downButton' >
                        <IconButton>
                            <KeyboardArrowDownIcon className='goDownWhite'/>
                        </IconButton>
                    </Scroll>
                </div>
            </div>  
        </div>
    )
}