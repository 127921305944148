import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import './TeamMember.css';

export default function TeamMember({ member }) {

  return (
  <Card class='memberroot'>
      <img src={member.imageUrl} alt="alternatetext" class='cardimage' ></img>
      <CardContent>
        <Typography
          gutterBottom
          variant="h3"
          component="h1"
          class='name'
        >
          {member.name}
        </Typography>
        <Typography
          gutterBottom
          variant="h5"
          component="h1"
          class='title'
        >
          {member.title}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          class='desc'
        >
          {member.desc}
        </Typography>
      </CardContent>
    </Card>
  );
}