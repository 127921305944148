const videos = [
    {
        title: 'The Content Creator',
        url: 'https://www.youtube.com/embed/7tyih7EQzco',
    },
    {
        title: 'The Visitor - content viewer',
        url: 'https://www.youtube.com/embed/E1WMPsQF2TI',
    }
];

export default videos;