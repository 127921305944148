import React from 'react';
import { CssBaseline } from '@material-ui/core';
import Header from '../Header';
import Slogan from '../Slogan';
import Why from '../Why';
import How from '../How';
import TryIt from '../TryIt';
import Downloads from '../Downloads';
import Presentations from '../Presentations';
import Team from '..//Team';
import Footer from '../Footer';
import './Home.css';

export default function Home() {
  return (
    <div className='home-root'>
      <CssBaseline/>
      <Header/>
      <Slogan/>
      <Why/>
      <Presentations/>
      <How/>
      <TryIt/>
      <Team/>
      <Footer/>
    </div>
  );
}