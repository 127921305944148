import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import template from '../assets/pictures/tipit_inverted.png'
import { Link as Scroll } from 'react-scroll';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: ' center',
        alignItems: ' center',
    },
    appbar: {
        backgroundColor: "#00AB2C",

    },
    appbarWrapper: {
        width: '95%',
        margin: '0 auto',
    },
    icon: {
        color: '#fff',
        fontSize: '3rem',
        alignSelf: 'right',
    },
    logo: {
        maxWidth: '5%',
        minWidth: '4em',
        padding: '.5%',
    },
    title: {
        flexGrow: '1',
    },
    colorText: {
        color: '#00AB2C',
    },
    slogan: {
        color: '#fff',
        fontSize: '1.5rem',
        textAlign: 'center',
        height: '10vh',
    },
    explanation: {
        color: '#fff',
        fontSize: '1rem',
        textAlign: 'center',
    },

    goDown: {
        color: '#fff',
        fontSize: '2rem',
    },

    card: {
        maxWidth: '100vh',
        background: 'none',
        margin: '20px',
        flexDirection: 'row',
        boxShadow: 'none !important',
      },
    desc: {
        fontFamily: 'Nunito',
        fontSize: '1.3rem',
        color: '#fff',
        textAlign: 'center',
    },
    mainButton: {
        color: '#fff',
        fontSize: 'calc((.1em + 0.5vmin) + (.1em + 0.5vmax))',
        alignItems: 'right',
        margin: '0.3em',
        padding: '0',
        display: 'inline-block',
        width: '15vh'

    },
    primaryButton: {
        color: 'limegreen',
        border: '1px solid limegreen',
        borderRadius: '0',
    },
    menuItem: {
        width: '30%',
        height: '30%',
        alignItems: 'right',
        padding: '0',
        margin: '0',
    },
    menu: {
        display: 'flex',
        justifyContent: ' center',
        flexDirection: 'row',
    },

}));

export default function Header() {
    const classes = useStyles();
    const [checked, setChecked] = useState(false);
    const [open, setOpen] = useState(false);
    const anchorRef = React.useRef(null);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);
    useEffect(()=>{
        setChecked(true);
    },[])

    return (
        <div className={classes.root} id="main">
            <AppBar className={classes.appbar} elevation={0}>
                <Toolbar className={classes.appbarWrapper}>
                    <div className={classes.title}>
                        <Scroll to="main" smooth={true}>
                            <img className={classes.logo} src={template} alt="al"/>
                        </Scroll>
                    </div>
                    <div className={classes.menu}>
                        <Link to='/' style={{ textDecoration: 'none' }} className={classes.menuItem}>
                            <Button className={classes.mainButton}>
                                Home
                            </Button>
                        </Link>
                        <Link to='/sign-up' style={{ textDecoration: 'none' }} className={classes.menuItem}>
                            <Button className={classes.mainButton}>
                                Create Account
                            </Button>
                        </Link>
                        <Link to='/login' style={{ textDecoration: 'none' }} className={classes.menuItem}>
                            <Button className={classes.mainButton}>
                            Log In
                            </Button>
                        </Link>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    )
}