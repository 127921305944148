import React from 'react';
import { IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link as Scroll } from 'react-scroll';
import texts from '../assets/content/texts'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import './Slogan.css';

export default function Slogan() {
    return (
        <div class='textRootWhite'>
            <Card class='card'>
                <div class='header'>
                    <div class='colorTextGreen'>You like it, tip it</div>
                </div>
                <CardContent>
                    <Typography
                        variant="body2"
                        component="p">
                        <p class='greenText'>{texts[0].text}</p>
                    </Typography>
                </CardContent>
                <div class='explanation'>
                    <Scroll to="why" smooth={true} className='downButton' >
                        <IconButton>
                            <KeyboardArrowDownIcon className='goDownGreen'/>
                        </IconButton>
                    </Scroll>
                </div>
            </Card> 
        </div>
    );
}