import React from 'react';
import { IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link as Scroll } from 'react-scroll';
import Card from '@material-ui/core/Card';
import './Slogan.css';
import { Link } from 'react-router-dom';

export default function TryIt() {
    return (
        <div class='textRootWhite' id='tryIt'>
            <Card class='card'>
                <Link to='/sign-up' style={{ textDecoration: 'none' }}>
                    <div class='header'>
                        <div class='colorTextGreen'>Try today: make an account!</div>
                    </div>
                    <div class='explanation'>
                        <Scroll to="team" smooth={true} className='downButton' >
                            <IconButton>
                                <KeyboardArrowDownIcon className='goDownGreen'/>
                            </IconButton>
                        </Scroll>
                    </div>
                </Link>
            </Card> 
        </div>
    );
}